<template>
  <div>
    <div class="d-flex flex-wrap align-center">
      <span v-if="!isMobile" class="text-subtitle-2 text-no-wrap black--text mr-2">
        {{ $t('marketplace.direct_deal') }}:
      </span>
      <c-select
        :value="dealTerm.type"
        :items="flatDealLimitTypes"
        item-text="label"
        item-value="value"
        :class="{ 'max-width-140': !isMobile }"
        height="30"
        :label="dealTermLabel"
        label-bold
        hide-details
        :disabled="isDisabled"
        @change="changeFlatDealLimitType($event)"
      />
      <div class="d-flex flex-nowrap align-center mt-4 mt-sm-0 ml-0 ml-sm-2">
        <c-btn
          depressed
          small
          label="—"
          color="primary"
          class="border-secondary mr-1"
          min-width="20"
          width="20"
          height="30"
          :disabled="dealTerm.value <= defaultSettings.minValue || isDisabled"
          @click="decreaseDealTerm()"
        />
        <c-text-field
          :value="dealTerm.value"
          label-bold
          background-color="white"
          height="30"
          type="number"
          :class="{ 'max-width-text-field': !isMobile }"
          border-color="secondary"
          hide-details
          :disabled="isDisabled"
          @input="dealTerm = { ...dealTerm, value: +$event }"
        />
        <c-btn
          depressed
          small
          label="+"
          color="primary"
          class="border-secondary ml-1"
          width="20"
          min-width="20"
          height="30"
          :disabled="dealTerm.value >= defaultSettings.maxValue || isDisabled"
          @click="increaseDealTerm()"
        />
      </div>
    </div>
    <errors
      v-if="errors.days || errors.total_traffic"
      :error-messages="errors.days || errors.total_traffic"
    />
  </div>
</template>

<script>
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import { mapState } from 'vuex'
  import FlatDeal from '@/services/classes/FlatDeal.js'
  import flatDealLimitTypes from '@/types/flat-deal-limit-types.js'

  const { DAYS, IMPRESSIONS } = flatDealLimitTypes

  export default {
    name: 'DealTerm',
    components: {
      Errors, CSelect, CBtn, CTextField
    },
    props: {
      value: {
        type: Object,
        required: true
      },
      flatDeal: {
        type: FlatDeal,
        required: true
      },
      errors: {
        type: Object,
        required: true
      },
      isDisabled: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      ...mapState('settings', ['flatDealApplicationLimitTypes']),
      dealTerm: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      flatDealLimitTypes() {
        const limitTypes = [
          { value: DAYS, label: this.$t('marketplace.days') },
          { value: IMPRESSIONS, label: this.$t('marketplace.impressions') }
        ]
        return this.flatDealApplicationLimitTypes.map((limitType) => {
          const foundLimitType = limitTypes.find(({ value }) => value === limitType)
          return foundLimitType ?? { value: limitType, label: limitType }
        })
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      dealTermLabel() {
        return this.isMobile ? this.$t('marketplace.direct_deal') : ''
      },
      maxWidthTextField() {
        return {
          [DAYS]: '60px',
          [IMPRESSIONS]: '100px'
        }[this.dealTerm.type]
      },
      defaultSettings() {
        return {
          [DAYS]: {
            minValue: this.flatDeal.minDays,
            maxValue: this.flatDeal.maxDays,
            step: 1
          },
          [IMPRESSIONS]: {
            minValue: this.flatDeal.minTraffic,
            maxValue: this.flatDeal.maxTraffic,
            step: 10 ** 6
          }
        }[this.dealTerm.type]
      }
    },
    methods: {
      changeFlatDealLimitType(limitType) {
        const defaultLimitValue = {
          [DAYS]: this.flatDeal.minDays,
          [IMPRESSIONS]: this.flatDeal.minTraffic
        }[limitType]
        this.dealTerm = {
          type: limitType,
          value: defaultLimitValue
        }
      },
      decreaseDealTerm() {
        const newValue = this.dealTerm.value - this.defaultSettings.step
        this.dealTerm = {
          ...this.dealTerm,
          value: newValue <= this.defaultSettings.minValue ? this.defaultSettings.minValue : newValue
        }
      },
      increaseDealTerm() {
        const newValue = this.dealTerm.value + this.defaultSettings.step
        this.dealTerm = {
          ...this.dealTerm,
          value: newValue >= this.defaultSettings.maxValue ? this.defaultSettings.maxValue : newValue
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .max-width-140 {
    max-width: 140px;
  }
  .max-width-text-field {
    max-width: v-bind(maxWidthTextField);
    min-width: v-bind(maxWidthTextField);
  }
  ::v-deep input[type='number'] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
    text-align: center;
  }
</style>
