<template>
  <v-dialog :value="isShown" max-width="650" @input="closeDialog()">
    <v-card class="pb-5 pb-sm-8" :loading="isLoading">
      <v-card-title class="d-block global-break-normal secondary py-2 px-5">
        <close-button class="mt-1" right absolute @click="closeDialog()" />
        <div class="font-weight-medium black--text">
          {{ $t('marketplace.selected_offer') }}
        </div>
      </v-card-title>
      <v-card-text class="px-2 px-sm-5">
        <offer-table dense :flat-deal="flatDeal" />
        <request-card
          v-if="isShown"
          :debounced-update-price-is-loading="debouncedUpdatePriceIsLoading"
          :is-loading="isLoading"
          :errors="errors"
          :deal-term="dealTerm"
          :flat-deal="flatDeal"
          class="border-primary rounded pa-4 pa-sm-6"
          @update-deal-term="$emit('update-deal-term', $event)"
          @send-request="$emit('send-request')"
        >
          <template #actions="{ buyIsLoading }">
            <c-btn
              depressed
              block
              color="primary"
              class="text-uppercase mt-2"
              :loading="buyIsLoading"
              :label="$t('main.close')"
              @click="closeDialog()"
            />
          </template>
        </request-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CloseButton from '@/components/CloseButton.vue'
  import FlatDeal from '@/services/classes/FlatDeal.js'
  import OfferTable from '@/components/flat-deal/OfferTable.vue'
  import RequestCard from '@/components/flat-deal/RequestCard.vue'

  export default {
    name: 'BuyDialog',
    components: {
      CBtn,
      RequestCard,
      OfferTable,
      CloseButton
    },
    props: {
      isShown: {
        type: Boolean,
        required: true
      },
      flatDeal: {
        type: FlatDeal,
        required: true
      },
      dealTerm: {
        type: Object,
        required: true
      },
      errors: {
        type: Object,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      },
      debouncedUpdatePriceIsLoading: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog')
      }
    }
  }
</script>
