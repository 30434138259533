<template>
  <div>
    <v-card class="box-shadow border-secondary" :loading="isLoading">
      <v-card-title v-if="!isMobile" class="justify-end pb-2">
        <deal-term
          :value="dealTerm"
          :flat-deal="flatDeal"
          :errors="errors"
          class="mb-2"
          :is-disabled="isLoading"
          @input="updateDealTerm($event)"
        />
        <v-spacer />
        <hot-offer v-if="flatDeal.isHotOffer" class="mr-3" />
        <v-spacer />
        <div class="d-flex align-center">
          <v-skeleton-loader :loading="debouncedUpdatePriceIsLoading" type="chip" class="mr-4 my-n3 ">
            <div v-if="flatDeal.totalDiscountRatio !== 1" class="text-subtitle-2 mb-n1">
              <span
                class="text-caption text-lg-subtitle-2 error--text text-decoration-line-through font-weight-medium mr-2"
              >
                ${{ toFixedByDefault(flatDeal.originalPrice) }}
              </span>
              <span
                class="text-caption text-lg-subtitle-2 font-weight-medium primary--text"
              >
                -{{ flatDeal.totalDiscountRatioToPercent }}
              </span>
            </div>
            <div class="black--text font-weight-black text-subtitle-1 text-lg-h6 text-center">
              ${{ replacePrice }}
            </div>
          </v-skeleton-loader>

          <c-btn
            depressed
            :label="$t('marketplace.buy')"
            color="primary"
            height="30"
            @click="onBuyBtnClick()"
          />
          <c-btn
            depressed
            outlined
            :label="$t('marketplace.more')"
            color="primary"
            height="30"
            class="ml-3"
            @click="openFlatDeal()"
          />
        </div>
      </v-card-title>
      <v-divider v-if="!isMobile" class="mx-4" />
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="12" sm="2" lg="1" class="mb-sm-4 d-flex flex-column-reverse flex-sm-column">
            <div
              class="d-flex justify-center align-center secondary rounded mb-lg-1 pa-2 mb-sm-1"
            >
              <v-img
                v-if="site.logo.src"
                :src="site.logo.src"
                contain
                width="100%"
                min-height="80"
                max-height="80"
                max-width="200"
              />
              <v-icon v-else size="100%" color="black" class="max-height-55">
                $earth
              </v-icon>
            </div>
            <div class="border-secondary rounded text-center text-caption font-weight-medium mb-1 mb-sm-0">
              {{ flatDeal.id }}
            </div>
          </v-col>
          <v-col cols="12" sm="10" lg="6">
            <v-row no-gutters class="justify-space-between">
              <v-col
                v-for="(parameter, index) in siteParams"
                :key="index"
                :cols="parameter.cols"
                :sm="parameter.sm"
                class="pb-4 text-caption"
              >
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="parameter.tooltip && on">
                      <div class="secondary-darken--text text-no-wrap text-truncate">
                        {{ parameter.name }}:
                      </div>
                      <div class="black--text text-lg-subtitle-2 font-weight-medium text-truncate">
                        {{ parameter.value }}
                      </div>
                    </div>
                  </template>
                  {{ parameter.tooltip }}
                </v-tooltip>
              </v-col>
              <v-spacer />
            </v-row>
          </v-col>
          <v-divider v-if="isDesktop" vertical class="mt-4 mb-5" />
          <v-col v-if="!isDesktop" cols="12" class="py-0">
            <v-divider />
          </v-col>
          <v-col cols="12" lg="5">
            <v-row>
              <v-col cols="12" sm="6">
                <v-row no-gutters class="justify-space-between text-caption">
                  <v-col cols="6" class="pb-4">
                    <div class="secondary-darken--text text-no-wrap text-truncate">
                      {{ $t('marketplace.format') }}:
                    </div>
                    <div class="black--text text-lg-subtitle-2 font-weight-medium text-truncate">
                      {{ flatDeal.placementType.adFormat.name }}
                    </div>
                  </v-col>
                  <v-col cols="6" class="pb-4">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <div class="secondary-darken--text text-no-wrap text-truncate">
                            {{ $t('marketplace.impressions') }}:
                          </div>
                          <div class="black--text text-lg-subtitle-2 font-weight-medium text-truncate">
                            {{ toFixedByDefault(flatDeal.trafficPerMonth / 10 ** 6) }}m
                          </div>
                        </div>
                      </template>
                      {{ $t('marketplace.tooltips.impressions') }}
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" class="pb-4">
                    <!-- eslint-disable vue/no-v-html -->
                    <div class="primary--text max-height my-n5 ml-n2" v-html="adFormatIcon" />
                    <!-- eslint-enable -->
                  </v-col>
                  <v-col cols="6" class="pb-4">
                    <div class="secondary-darken--text text-no-wrap text-truncate">
                      {{ $t('marketplace.ad_placement') }}:
                    </div>
                    <span class="black--text text-lg-subtitle-2 font-weight-medium">
                      {{ flatDeal.placementType.name }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider v-if="!isMobile" vertical class="mt-4 mb-5" />
              <v-col v-if="isMobile" cols="12" class="py-0">
                <v-divider />
              </v-col>
              <v-col cols="12" sm="6">
                <v-row no-gutters class="text-caption fill-height">
                  <v-col cols="6" class="pb-4">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <div class="secondary-darken--text text-no-wrap text-truncate text-truncate">
                            {{ $t('marketplace.cpm') }}:
                          </div>
                          <div class="black--text text-lg-subtitle-2 font-weight-medium text-truncate">
                            ${{ toFixedByDefault(flatDeal.flatBid) }}
                          </div>
                        </div>
                      </template>
                      {{ $t('marketplace.tooltips.cpm') }}
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" class="pb-4">
                    <v-tooltip top max-width="400">
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <div class="secondary-darken--text text-no-wrap text-truncate">
                            {{ $t('marketplace.countries') }}:
                          </div>
                          <div class="black--text text-lg-subtitle-2 font-weight-medium text-truncate">
                            {{ flatDeal.countryGroup.name }}
                          </div>
                        </div>
                      </template>
                      {{ countryList }}
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" class="pb-4">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <div class="secondary-darken--text text-no-wrap text-truncate">
                            {{ $t('marketplace.device_type') }}:
                          </div>
                          <div class="black--text text-lg-subtitle-2 font-weight-medium text-truncate">
                            <v-icon v-if="flatDeal.trafficTypeIcon" color="black" size="18">
                              {{ flatDeal.trafficTypeIcon }}
                            </v-icon>
                            <span v-else class="text-capitalize">{{ flatDeal.trafficType }}</span>
                          </div>
                        </div>
                      </template>
                      {{ $t('marketplace.tooltips.device_type') }}
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" class="pb-4">
                    <div class="secondary-darken--text text-no-wrap text-truncate">
                      {{ $t('marketplace.price_model') }}:
                    </div>
                    <custom-chip-status
                      small
                      label
                      outlined
                      :value="flatDeal.pricingModel"
                      :settings="pricingModels"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider v-if="isMobile" />
      <v-card-actions v-if="isMobile" class="d-block px-4">
        <hot-offer v-if="flatDeal.isHotOffer" class="mr-3 justify-end" />
        <deal-term
          :value="dealTerm"
          :flat-deal="flatDeal"
          :errors="errors"
          class="mb-2"
          :is-disabled="isLoading"
          @input="updateDealTerm($event)"
        />
        <div v-if="flatDeal.totalDiscountRatio !== 1" class="d-flex justify-center">
          <div class="secondary-darken--text text-no-wrap text-truncate">
            {{ $t('marketplace.old_price') }}:
            <span
              class="text-caption text-lg-subtitle-2 error--text text-decoration-line-through font-weight-medium mr-2"
            >
              ${{ toFixedByDefault(flatDeal.originalPrice) }}
            </span>
          </div>
          <div class="secondary-darken--text text-no-wrap text-truncate">
            {{ $t('marketplace.discount') }}:
            <span
              class="text-caption text-lg-subtitle-2 font-weight-medium primary--text"
            >
              -{{ flatDeal.totalDiscountRatioToPercent }}
            </span>
          </div>
        </div>
        <div class="black--text font-weight-black text-subtitle-1 text-center">
          ${{ replacePrice }}
        </div>
        <v-row>
          <v-col cols="6">
            <c-btn
              depressed
              block
              :label="$t('marketplace.buy')"
              color="primary"
              height="30"
              @click="onBuyBtnClick()"
            />
          </v-col>
          <v-col cols="6">
            <c-btn
              depressed
              outlined
              block
              :label="$t('marketplace.more')"
              color="primary"
              height="30"
              @click="openFlatDeal()"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <buy-dialog
      :is-shown="buyDialogIsShown"
      :flat-deal="flatDeal"
      :deal-term="dealTerm"
      :errors="errors"
      :is-loading="isLoading"
      :debounced-update-price-is-loading="debouncedUpdatePriceIsLoading"
      @close-dialog="buyDialogIsShown = false"
      @update-deal-term="updateDealTerm($event)"
      @send-request="$emit('send-request')"
    />
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import axios from 'axios'
  import { mapGetters, mapState } from 'vuex'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import debounce from 'debounce'
  import FlatDeal from '@/services/classes/FlatDeal.js'
  import flatDealLimitTypes from '@/types/flat-deal-limit-types.js'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'
  import DealTerm from '@/views/Marketplace/DealTerm.vue'
  import BuyDialog from '@/views/Marketplace/BuyDialog.vue'
  import { flatDealsRepository } from '@/services/repository-factory.js'
  import searchCountriesInGroup from '@/services/utils/search-countries-in-group.js'
  import handleErrors from '@/services/handleErrors.js'
  import HotOffer from '@/views/Marketplace/HotOffer.vue'
  import routeNames from '@/types/route-names.js'

  const { DAYS, IMPRESSIONS } = flatDealLimitTypes

  export default {
    name: 'FlatDealCard',
    components: {
      HotOffer, BuyDialog, DealTerm, CustomChipStatus, CBtn
    },
    props: {
      flatDeal: {
        type: FlatDeal,
        required: true
      }
    },
    data() {
      return {
        adFormatIcon: '',
        dealTerm: {
          type: null,
          value: null
        },
        buyDialogIsShown: false,
        errors: {},
        isLoading: false,
        debouncedUpdatePriceIsLoading: false
      }
    },
    computed: {
      ...mapState('campaigns', ['pricingModels']),
      ...mapState('settings', ['flatDealCountryGroups', 'flatDealDefaultLimitType', 'flatDealDefaultValueKey']),
      ...mapGetters('settings', ['getCasedFlatDealDefaultValueKey']),
      countryList() {
        return searchCountriesInGroup(
          this.flatDealCountryGroups,
          this.flatDeal.countryGroup.id
        )
      },
      site() {
        return this.flatDeal.site
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      isDesktop() {
        return this.$vuetify.breakpoint.lgAndUp
      },
      siteParams() {
        return [
          {
            name: this.$t('marketplace.domain'),
            value: this.site.domain,
            sm: 2,
            cols: 4
          },
          {
            name: this.$t('marketplace.total_visits'),
            value: this.totalVisits,
            tooltip: this.$t('marketplace.tooltips.total_visits'),
            sm: 2,
            cols: 8
          },
          {
            name: this.$t('marketplace.vertical'),
            value: this.site.categoryGroup.name,
            sm: 3,
            cols: 4
          },
          {
            name: this.$t('marketplace.top_countries'),
            value: this.topCountries || '-',
            tooltip: this.$t('marketplace.tooltips.top_countries'),
            sm: 5,
            cols: 8
          },
          {
            name: this.$t('marketplace.global_rank'),
            value: `#${this.site.siteReport.similarwebGlobalRank || '-'}`,
            tooltip: this.$t('marketplace.tooltips.global_rank'),
            sm: 2,
            cols: 4
          },
          {
            name: this.$t('marketplace.page_views'),
            value:
              `${toFixedByDefault(
                this.flatDeal.site.siteReport.similarWebPageViews / 10 ** 6
              )}m`,
            sm: 2,
            cols: 8
          },
          {
            name: this.$t('marketplace.audience'),
            value: this.gendersToPercents,
            tooltip: this.$t('marketplace.tooltips.audience'),
            sm: 3,
            cols: 4
          }
        ]
      },
      replacePrice() {
        return toFixedByDefault(this.flatDeal.price).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ')
      },
      topCountries() {
        const sortedCountries = [...this.site.siteReport.similarwebTopCountries].sort((a, b) => b.value - a.value)
        const topThreeCountries = sortedCountries.slice(0, 3)
        const topThreeCountriesReplace = topThreeCountries.map((country) => `${country.country.isoCode2} ${(country.value * 100).toFixed()} %`)
        return topThreeCountriesReplace.join(', ')
      },
      gendersToPercents() {
        const malePercent = (this.site.siteReport.similarwebMale * 100).toFixed()
        const femalePercent = (this.site.siteReport.similarwebFemale * 100).toFixed()
        return `M ${malePercent}%, F ${femalePercent}%`
      },
      totalVisits() {
        if (this.site.siteReport.similarwebTotalVisits === null) {
          return '-'
        }

        return `${toFixedByDefault(this.site.siteReport.similarwebTotalVisits / 10 ** 6)}m`
      }
    },
    created() {
      this.fetchImage()
      this.dealTerm = {
        type: this.flatDealDefaultLimitType,
        value: this.flatDeal[this.getCasedFlatDealDefaultValueKey]
      }
      this.debouncedUpdatePriceFlatDeal = debounce(this.updatePriceFlatDeal, 1500)
    },
    methods: {
      onBuyBtnClick() {
        this.$emit('flat-buy-click')
        this.buyDialogIsShown = true
      },
      async fetchImage() {
        const url = this.flatDeal.placementType.adFormat.icon

        if (!url) return

        try {
          const { data } = await axios(url)
          this.adFormatIcon = data
        } catch {
          this.adFormatIcon = ''
        }
      },
      updateDealTerm(value) {
        this.dealTerm = value
        this.debouncedUpdatePriceIsLoading = true
        this.debouncedUpdatePriceFlatDeal()
      },
      async updatePriceFlatDeal() {
        this.isLoading = true
        this.errors = {}
        const params = {
          limit_type: this.dealTerm.type,
          ...this.dealTerm.type === DAYS && { days: this.dealTerm.value },
          ...this.dealTerm.type === IMPRESSIONS && { total_traffic: this.dealTerm.value }
        }
        try {
          const response = await flatDealsRepository.price(this.flatDeal.id, params)
          this.flatDeal.updatePrice(response)
        } catch (error) {
          this.errors = handleErrors(error)
        }
        this.isLoading = false
        this.debouncedUpdatePriceIsLoading = false
      },
      async openFlatDeal() {
        this.$emit('open-flat-deal')
        await this.$router.push({
          name: routeNames.FLAT_DEAL,
          params: { id: this.flatDeal.id },
          query: {
            limitType: this.dealTerm.type,
            value: this.dealTerm.value
          }
        })
      },
      toFixedByDefault
    }
  }
</script>

<style lang="scss" scoped>
  .box-shadow {
    box-shadow: 0 0 5px rgba(33, 33, 33, 0.19) !important;
  }
  .max-height {
    height: 80px;
    width: 80px;
  }
  .max-height-55 {
    max-height: 55px;
  }
</style>
