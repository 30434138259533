<template>
  <v-data-table
    :headers="headers"
    :items="tableItems"
    :mobile-breakpoint="0"
    hide-default-footer
    hide-default-header
    :items-per-page="-1"
    v-bind="$attrs"
    class="my-2"
  >
    <template #item.name="{ item }">
      <div class="d-flex">
        <v-icon color="primary" size="18" class="mr-2">
          {{ item.icon }}
        </v-icon>
        <span class="secondary-darken--text">
          {{ item.name }}
        </span>
      </div>
    </template>
    <template #item.result="{ item }">
      <custom-chip-status
        v-if="item.isChip"
        label
        small
        outlined
        :value="item.result.value"
        :settings="item.result.settings"
      />
      <div v-else-if="item.isIcon">
        <v-icon v-if="item.result.icon" color="black" size="18">
          {{ item.result.icon }}
        </v-icon>
        <span v-else class="black--text text-capitalize font-weight-bold">{{ item.result.value }}</span>
      </div>
      <a
        v-else-if="item.isLink"
        :href="'https://' + item.result"
        target="_blank"
        class="info--text font-weight-bold"
      >
        {{ item.result }}
      </a>
      <div v-else-if="item.tooltip" class="d-flex justify-end">
        <v-tooltip top max-width="400">
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" class="black--text font-weight-bold" v-on="on">
              {{ item.result }}
            </div>
          </template>
          <span>{{ item.tooltip }}</span>
        </v-tooltip>
      </div>
      <div v-else class="black--text text-truncate font-weight-bold ml-auto max-width ">
        {{ item.result }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import { mapState } from 'vuex'
  import searchCountriesInGroup from '@/services/utils/search-countries-in-group.js'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'
  import FlatDeal from '@/services/classes/FlatDeal.js'

  export default {
    name: 'OfferTable',
    components: {
      CustomChipStatus
    },
    props: {
      flatDeal: {
        type: FlatDeal,
        required: true
      },
      excludeHeader: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapState('campaigns', ['pricingModels']),
      ...mapState('settings', ['flatDealCountryGroups']),
      headers() {
        return [
          { value: 'name', align: 'start' },
          { value: 'result', align: 'end' }
        ]
      },
      tableItems() {
        return [
          {
            name: this.$t('marketplace.domain'),
            icon: '$earth-search',
            result: this.flatDeal.site.domain,
            isLink: true,
            isShown: !this.excludeHeader.includes('domain')
          },
          {
            name: this.$t('marketplace.format'),
            icon: '$sound',
            result: this.flatDeal.placementType.adFormat.name
          },
          {
            name: this.$t('marketplace.price_model'),
            icon: '$dollar-outlined',
            result: {
              value: this.flatDeal.pricingModel,
              settings: this.pricingModels
            },
            isChip: true
          },
          {
            name: this.$t('marketplace.spot'),
            icon: '$sound',
            result: this.flatDeal.placementType.name
          },
          {
            name: this.$t('marketplace.countries_tier'),
            icon: '$location',
            result: this.flatDeal.countryGroup.name,
            tooltip: this.countryList
          },
          {
            name: this.$t('marketplace.impressions'),
            icon: '$monitor',
            result: `${toFixedByDefault(this.flatDeal.trafficPerMonth / 10 ** 6)}m`
          },
          {
            name: this.$t('marketplace.device_type'),
            icon: '$monitor-mobile',
            result: {
              value: this.flatDeal.trafficType,
              icon: this.flatDeal.trafficTypeIcon
            },
            isIcon: true
          }
        ].filter(({ isShown }) => isShown !== false)
      },
      countryList() {
        return searchCountriesInGroup(
          this.flatDealCountryGroups,
          this.flatDeal.countryGroup.id
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
  .max-width {
    max-width: 125px;
  }
</style>
