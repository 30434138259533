<template>
  <div class="d-flex text-no-wrap align-center text-caption mr-3">
    <v-icon color="error" size="16">
      $lightning
    </v-icon>
    <div class="font-weight-black error--text text-uppercase ml-1">
      {{ $t('marketplace.hot_offer') }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HotOffer'
  }
</script>
