<template>
  <div v-if="!flatDealApplication.id" class="rounded pa-4 pa-sm-6">
    <c-select
      :value="selectedAd"
      :placeholder="$t('marketplace.select_ad')"
      :items="adsListMarkedDisabled"
      :loading="adsAreLoading"
      item-value="id"
      item-text="newName"
      height="46"
      return-object
      hide-details="auto"
      :error-messages="buyFlatErrors.ad_id"
      @change="setSelectedAd($event)"
    >
      <template #label>
        <div class="black--text text-body-2">
          <span class="font-weight-medium">
            {{ $t('marketplace.select_ad') }}
          </span>
        </div>
      </template>
    </c-select>
    <div class="d-flex justify-space-between mt-2">
      <deal-term
        :value="dealTerm"
        :flat-deal="flatDeal"
        :errors="errors"
        :is-disabled="isLoading"
        @input="$emit('update-deal-term', $event)"
      />
      <hot-offer v-if="flatDeal.isHotOffer && $vuetify.breakpoint.mdAndUp" class="mr-3" />
    </div>

    <v-row class="my-4">
      <v-col cols="auto">
        <div>
          <span class="secondary-darken--text mr-1"> {{ $t('marketplace.cpm') }}: </span>
          <span class="black--text">${{ toFixedByDefault(flatDeal.flatBid) }}</span>
        </div>
        <div class="d-flex">
          <div class="secondary-darken--text mr-1">
            {{ $t('marketplace.discount') }}:
          </div>
          <div
            :class="[
              'text-subtitle-2 text-truncate',
              !!flatDeal.totalDiscountRatio ? 'primary--text' : 'black--text'
            ]"
          >
            <v-skeleton-loader
              :loading="debouncedUpdatePriceIsLoading"
              type="chip"
              class="c-skeleton-loader"
            >
              {{ flatDeal.totalDiscountRatioToPercent }}
            </v-skeleton-loader>
          </div>
        </div>
        <div class="d-flex">
          <div class="secondary-darken--text text-no-wrap mr-1">
            {{ $t('marketplace.old_price') }}:
          </div>
          <div
            :class="[
              'text-subtitle-2 text-truncate',
              !!flatDeal.totalDiscountRatio
                ? 'error--text text-decoration-line-through'
                : 'black--text'
            ]"
          >
            <v-skeleton-loader
              :loading="debouncedUpdatePriceIsLoading"
              type="chip"
              class="c-skeleton-loader"
            >
              ${{ toFixedByDefault(flatDeal.originalPrice) }}
            </v-skeleton-loader>
          </div>
        </div>
      </v-col>
      <v-col
        v-if="flatDeal.isHotOffer && $vuetify.breakpoint.smAndDown"
        class="d-flex align-center"
      >
        <hot-offer v-if="flatDeal.isHotOffer" class="mr-3" />
      </v-col>
      <v-col cols="11" sm="8" md="6" class="px-sm-0">
        <div class="d-flex align-center border-secondary rounded fill-height px-2">
          <span class="secondary-darken--text mr-1">
            {{ flatDealEstimated.text }}:
          </span>
          <v-skeleton-loader
            :loading="debouncedUpdatePriceIsLoading"
            type="chip"
            class="c-skeleton-loader black--text text-subtitle-1 ml-2"
          >
            ~{{ flatDealEstimated.value }}
          </v-skeleton-loader>
        </div>
      </v-col>
      <div class="d-flex align-center mx-auto">
        <v-tooltip top max-width="160">
          <template #activator="{ on, attrs }">
            <v-icon color="warning" size="18" v-bind="attrs" v-on="on">
              $warning-outlined
            </v-icon>
          </template>
          <span>{{ $t('marketplace.warning_tooltip') }}</span>
        </v-tooltip>
      </div>
    </v-row>
    <div class="d-flex justify-center align-center black--text text-h6 font-weight-black">
      {{ $t('marketplace.total_price') }}:
      <v-skeleton-loader
        :loading="debouncedUpdatePriceIsLoading"
        type="chip"
        class="c-skeleton-loader ml-2"
      >
        {{ flatDealTotalPrice }}
      </v-skeleton-loader>
    </div>
    <c-btn
      depressed
      block
      color="primary"
      class="text-uppercase"
      :loading="buyIsLoading || debouncedUpdatePriceIsLoading || isLoading"
      :label="$t('marketplace.buy_now')"
      @click="buyFlatDeal()"
    />
    <div class="d-flex justify-center">
      <v-checkbox
        v-model="isConfirmed"
        :error="confirmedIsError"
        color="primary"
        class="text-body-2 black--text"
        hide-details
        @change="confirmedIsError = false"
      >
        <template #label>
          <div class="d-flex flex-wrap">
            <div class="text-no-wrap mr-1">
              {{ $t('marketplace.terms_conditions.text') }}
            </div>
            <a
              :href="helpUrls.terms_and_conditions"
              target="_blank"
              class="text-no-wrap info--text"
              @click.stop
            >
              {{ $t('marketplace.terms_conditions.link') }}
            </a>
          </div>
        </template>
      </v-checkbox>
    </div>
  </div>
  <div v-else class="border-primary rounded pa-4 pa-sm-6 black--text text-center">
    {{ $t('marketplace.successfully_message') }}
    <div class="d-flex flex-wrap justify-center">
      {{ $t('marketplace.start_and_approval.first') }}
      <router-link :to="{ name: routeNames.CAMPAIGNS }" class="mx-1">
        {{ $t('marketplace.start_and_approval.link') }}
      </router-link>
      {{ $t('marketplace.start_and_approval.second') }}
    </div>
    {{ $t('marketplace.campaign_will_available') }}
    <div>
      {{ $t('marketplace.download_io.text') }}
      <button type="button" class="primary--text text-decoration-underline" @click="fetchFlatDealDocument()">
        {{ $t('marketplace.download_io.link') }}
      </button>
    </div>
    <slot name="actions" :buy-is-loading="buyIsLoading" />
  </div>
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import { mapGetters } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import DealTerm from '@/views/Marketplace/DealTerm.vue'
  import HotOffer from '@/views/Marketplace/HotOffer.vue'
  import FlatDeal from '@/services/classes/FlatDeal.js'
  import flatDealLimitTypes from '@/types/flat-deal-limit-types.js'
  import { adsRepository, flatDealsRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'
  import routeNames from '@/types/route-names.js'

  const { DAYS, IMPRESSIONS } = flatDealLimitTypes

  export default {
    name: 'RequestCard',
    components: {
      HotOffer, DealTerm, CBtn, CSelect
    },
    props: {
      flatDeal: {
        type: FlatDeal,
        required: true
      },
      dealTerm: {
        type: Object,
        required: true
      },
      errors: {
        type: Object,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      },
      debouncedUpdatePriceIsLoading: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        routeNames,
        buyIsLoading: false,
        downloadIsLoading: false,
        adsAreLoading: false,
        isConfirmed: false,
        confirmedIsError: false,
        adsList: [],
        selectedAd: null,
        buyFlatErrors: {},
        flatDealApplication: {}
      }
    },
    computed: {
      ...mapGetters('settings', ['helpUrls']),
      adFormat() {
        return this.flatDeal.placementType.adFormat
      },
      flatDealTotalPrice() {
        return `$${toFixedByDefault(this.flatDeal.price)}`
      },
      flatDealEstimated() {
        return {
          [DAYS]: {
            text: this.$t('marketplace.estimated_impressions'),
            value: this.flatDeal.totalTraffic
          },
          [IMPRESSIONS]: {
            text: this.$t('marketplace.estimated_days'),
            value: this.flatDeal.days
          }
        }[this.dealTerm.type] ?? {}
      },
      computedAdsList() {
        if (this.adFormat.type === adFormats.INTERSTITIAL) {
          return this.adsList.filter((interstitial) => interstitial.size === this.adFormat.size)
        }

        if (this.adFormat.type === adFormats.BANNER) {
          return this.adsList.filter((banner) => banner.size === this.adFormat.size)
        }

        if (this.adFormat.type === adFormats.POPUNDER) {
          return this.adsList.map((popunder) => ({
            ...popunder,
            newName: `ID ${popunder.id} "${popunder.name}"`
          }))
        }
        return this.adsList
      },
      adsListMarkedDisabled() {
        return this.computedAdsList.map((item) => {
          if (item.latest_approval?.status === 'rejected') {
            return {
              ...item,
              newName: `${this.$t('marketplace.approval_statuses_rejected')}: ${item.newName || item.name} ${item.url ? `(${item.url.value})`
                : ''}`,
              disabled: true
            }
          }
          const countUrls = item.model.creatives ? `(${item.model.creatives.length} url)` : ''
          return {
            ...item,
            newName: `${item.newName || item.name} ${item.url ? `(${item.url.value})` : ''} ${countUrls}`
          }
        })
      }
    },
    async created() {
      await this.fetchAdsForAdFormat()
    },
    methods: {
      async buyFlatDeal() {
        if (!this.isConfirmed) {
          this.confirmedIsError = true
          return
        }

        this.buyIsLoading = true
        const params = {
          [this.dealTerm.type]: this.dealTerm.value,
          ad_id: this.selectedAd?.id,
          flat_deal_id: this.flatDeal.id,
          limit_type: this.dealTerm.type,
          ...this.dealTerm.type === DAYS && { days: this.dealTerm.value },
          ...this.dealTerm.type === IMPRESSIONS && { total_traffic: this.dealTerm.value }
        }
        try {
          this.flatDealApplication = await flatDealsRepository.store(params)
          this.$emit('send-request')
        } catch (error) {
          this.buyFlatErrors = handleErrors(error)
        }
        this.buyIsLoading = false
      },
      async fetchAdsForAdFormat() {
        this.adsAreLoading = true
        try {
          this.adsList = await adsRepository.forCampaignForm({
            ad_format_type: this.adFormat.type
          })
        } catch (error) {
          handleErrors(error)
        }
        this.adsAreLoading = false
      },
      setSelectedAd(currentAd) {
        this.selectedAd = currentAd
      },
      async fetchFlatDealDocument() {
        this.downloadIsLoading = true
        try {
          await flatDealsRepository.document(this.flatDealApplication.id)
        } catch (error) {
          handleErrors(error)
        }
        this.downloadIsLoading = false
      },
      toFixedByDefault
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .c-skeleton-loader .v-skeleton-loader__chip {
    height: 20px;
  }
</style>
